@import '~@sartorius/ui/scss/variables';
@import '~@coreui/coreui/scss/mixins/breakpoints';

.navigationButtons {
  margin-top: 2rem;

  button:hover {
    img {
      filter: invert(1);
    }
  }

  button {
    &.disabled,
    &:disabled {
      opacity: 1 !important;
      color: #838383 !important;
      outline-color: #d2d2d2 !important;

      &:hover {
        cursor: not-allowed;
      }

      img {
        filter: invert(0);
      }
    }
  }
}

.navigationTitle {
  font-size: 2.4rem;
}


.navigationTitleRecommendation {
  font-size: 1.7rem;
}
