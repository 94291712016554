@import '~@sartorius/ui/scss/variables';

.selectionBox {
  border-bottom-color: $gray-300 !important;
}

.dataPrivacyLink {
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
}

@media (max-width: 768px) {
  .mobileBottom {
    order: +2;
  }
}

.rowStyle {
  display: 'flex'; 
  flex-direction: column;
}
