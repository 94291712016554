.successPageMessage {
  padding: 4rem 10rem 6rem;
}

.successPageBoxes {
  margin: 5rem 0;
}

.boxHeadline {
  min-height: 48px;
}

.cardBody {
  min-height: 211px;
}

.button {
  position: absolute;
  bottom: 2rem;
}
