.arrow {
  margin-left: 1rem;
}

.arrowRotated {
  transform: rotate(180deg);
}

.hiddenDetailsText {
  font-size: 14px;
}

@media (max-width: 768px) {
  .detailsButton {
    display: block;
    margin-top: 2.4rem;
  }
}

@media (min-width: 768px) {
  .detailsButton {
    display: none;
  }
}
