@import '~@sartorius/ui/scss/variables';
@import '~@coreui/coreui/scss/mixins/breakpoints';

$arrowBorderSize: 32px;
$navigationBackgroundColor: #f5f5f5;

.step {
  background-color: $black;
  display: flex;
  position: relative;
  align-items: center;
  flex: 1;
  height: 64px;
  padding: 10px;
  font-size: 14px;
  color: $white;

  .navigationContentWrapper {
    display: flex;
    align-items: center;
    margin: 0 0 0 10px;
    flex: 1 1 0px;
  }

  &:nth-of-type(n + 2) {
    margin-left: 5px;
    .navigationContentWrapper {
      margin-left: 40px;
    }
  }

  &:before {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: $arrowBorderSize solid transparent;
    border-bottom: $arrowBorderSize solid transparent;
    border-left: $arrowBorderSize solid $navigationBackgroundColor;
    position: absolute;
    top: 50%;
    margin-top: -$arrowBorderSize;
    margin-left: 10px;
    left: 100%;
    z-index: 1;
  }

  &:after {
    content: ' ';
    display: block;
    width: 0;
    height: 0;
    border-top: $arrowBorderSize solid transparent; /* Go big on the size, and let overflow hide */
    border-bottom: $arrowBorderSize solid transparent;
    border-left: $arrowBorderSize solid $black;
    position: absolute;
    top: 50%;
    margin-top: -$arrowBorderSize;
    left: 100%;
    z-index: 2;
  }

  &:hover {
    background-color: $yellow;
    cursor: pointer;
    color: $black;

    .iconContainer {
      border-color: $black;
    }
    img,
    .icon {
      filter: invert(0);
    }

    span {
      border-color: $black !important;
      color: $black !important;
    }

    &:after {
      border-left: $arrowBorderSize solid $yellow;
    }
  }
}

.activeStep {
  background-color: $yellow;
  color: $black;

  .iconContainer {
    border-color: $black;
  }

  img,
  .icon {
    filter: invert(0);
  }

  &:after {
    border-left: $arrowBorderSize solid $yellow;
  }
}

.requestStep {
  margin-left: 40px;
  width: 140px;
  background-color: $black;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: $yellow;
    color: $black;
  }
  &:before,
  &:after {
    display: none;
  }

  .textContainer {
    text-align: center;
    height: 100%;
    align-self: center;
    margin-left: 0;
  }
  .navigationContentWrapper {
    min-height: 49px;
    display: flex;
  }
}

.activeRequestStep {
  @extend .requestStep;

  background-color: $yellow;
  color: $black;
}

.iconContainer {
  padding: 0px 5px 3px 5px;
  border: 1px solid $white;
}

.textContainer {
  margin-left: 10px;
  text-align: left;
}

.icon {
  filter: invert(1);
}

.disableNextSteps {
  pointer-events: none !important;
  opacity: 0.5 !important;
  cursor: not-allowed !important;
}

@media screen and (max-width: 991.98px) {
  .step {
    background-color: transparent;
    margin-bottom: 2px;
    margin-right: 0 !important;
    color: $black;
    border: 1px solid $black;

    .iconContainer {
      border-color: $black;
      img {
        filter: invert(0);
      }
    }
    .navigationContentWrapper {
      margin-left: 0;
    }

    .textContainer {
      flex-direction: row;
      display: flex;
      margin: 16px 10px 10px 10px;
      div:nth-of-type(2) {
        padding-left: 3px;
      }
    }
    &:after,
    &:before {
      display: none;
    }
    .requestStep {
      border: 1px solid $yellow;
    }

    &:hover {
      background-color: $black;
      color: $white;
      .iconContainer {
        border-color: $white;
        img {
          filter: invert(1);
        }
      }
    }

    &:nth-of-type(n + 2) {
      margin-left: 0;

      .navigationContentWrapper {
        margin-left: 0;
      }
    }
  }

  .activeStep {
    background-color: $black;
    color: $white;

    .iconContainer {
      border-color: $white;
      img {
        filter: invert(1);
      }
    }
    .navigationContentWrapper {
      margin-bottom: 3px;
    }
  }

  .requestStep {
    width: 100%;
    margin-left: 0;
  }
}
