.headline {
  font-family: "TTNormsPro-Medium";
  font-size: 1.4rem;
  color: #fff;
  line-height: 2.4rem;
}

.copyright {
  margin-top: 30px;
}

.list {
  padding: 0;
  li {
    list-style: none;
    padding: 0;
    font-size:1.4rem;
    line-height:2.4rem;
    a {
      color:#fff;
      text-decoration:none;
      padding:0;
      &:focus,
      &:hover {
        color:#000;
        background-color: rgb(255, 237, 0);
      }
    }
  }
}
