
@import "~@sartorius/ui/scss/_variables.scss";
@import '~@coreui/coreui/scss/mixins/breakpoints';

.langDropdownIcon {
    width: 1.6rem;
    margin-right: 1rem;
    font-size: 1.6rem;
}

.whiteColor {
  color: white;
}

.languageDropdown {
  overflow-y: auto;
  max-height: 50rem;
}