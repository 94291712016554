@import '~@sartorius/ui/scss/variables';
@import '~@coreui/coreui/scss/mixins/breakpoints';

.navigation {
  display: flex;
}

@include media-breakpoint-down(md, $container-max-widths) {
  .navigation {
    flex-direction: column;
  }
}
