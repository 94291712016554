
@import "~@sartorius/ui/scss/_variables.scss";
@import '~@coreui/coreui/scss/mixins/breakpoints';
.headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-direction: row;   
    height: 70px;
}

:global(.c-header) :global(.c-header-brand) .vwrImage {
  height: 4.6rem;
  width: auto !important;
}

.vwrHeaderColor {
  background: #236192;
}

.headerText {
  font-size: 2.3rem;
}

.vwrHeaderContainer {
  color: white;
}

.navContainer {
    align-items: stretch;
    li {   
        margin-bottom: 0;
        a {
            height: 100%;
            display: flex;
            align-items: center;
            &:hover {
            color: white;
            background: black;
            }
        }
    }
}

.secondaryHeaderText {
  font-size: 1.75rem;
}

.nextButtonAndTextWrapper {
  align-content: center;
}

.circleImg {
  width: 100px;
}

.stepContainer {
  display: flex;
}

.topOfButtonText {
  padding-left: 1rem;
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.nextButtonLabel {
  font-size: 10px;
}