@import '~@sartorius/ui/scss/variables';
@import '~@coreui/coreui/scss/mixins/breakpoints';

.active {
  background-color: $primary;

  label {
    color: $gray-800 !important;
  }

  .newProductTag {
    background-color: $black;
    color: $white;

    p {
      font-weight: 400;
    }
  }
}

.card {
  min-width: 350px;
  max-width: 350px;
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
  margin-bottom: 2.75rem !important;
}

.btnSelect {
  &.disabled,
  &:disabled {
    opacity: 1 !important;
    color: #838383 !important;
    outline-color: #d2d2d2 !important;

    &:hover {
      background-color: transparent !important;
      cursor: not-allowed;
    }
  }
}

.newProductContainer {
  display: flex;
  margin-top: -2.25rem;
}

.newProductEmpty {
  flex-grow: 1;
}

.newProductTag {
  background-color: $primary;
  color: $black;

  p {
    font-size: 14px;
    margin-bottom: 0;
    padding: 4px 6px;
    font-weight: bold;
  }
}
